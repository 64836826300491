import React, { useEffect, useState } from 'react';
import { PanelHeader, AppRoot, View, Panel, Div, Placeholder, Button, Title, Text, Caption } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import bridge from '@vkontakte/vk-bridge';
import './style.css';

const link_app = "https://vk.com/app7625823"

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const App = () => {
  const [currentPanel, setCurrentPanel] = useState('start');

  async function play_music() {
    let greetings = document.createElement("audio");
    greetings.src = "https://action-map.dnatree.ru/music.mp3";
    greetings.play()
    setCurrentPanel("main")
    setTimeout(() => {
      bridge.send("VKWebAppShowNativeAds", {ad_format:"reward"})
    }, 2000)
  }

  function shareUrl() {
    bridge.send("VKWebAppShare", {"link": link_app})
    .finally(() => {
    });
  }

  function shareStory() {
    bridge.send("VKWebAppShowStoryBox", { "background_type" : "image", "url" : "http://action-map.dnatree.ru/story.png", "attachment": {"text": "open", "type": "url", "url": link_app} })
    .then(res => console.log(res.result))
    .catch(error => console.log(error));
  }

  useEffect(() => {
  }, []);

  return (
    <AppRoot>
      <View activePanel={currentPanel}>
        <Panel id='start'>
        <div class="parent">
            <div class="block">
                <Title level="1" weight="heavy" style={{ marginBottom: 16 }}>Добро пожаловать!</Title>
                <Text weight="medium" style={{ marginBottom: 16 }}>Наша команда крайне долго разрабатывала его, чтобы сделать нечто особенное...</Text>
                <Button stretched onClick={play_music}>Что же там?...</Button>
            </div>
        </div>
        </Panel>
        <Panel id='main'>
        <div class="parent">
            <div class="block">
                <Title level="1" weight="heavy" style={{ marginBottom: 16 }}>Ха! Ты попался!</Title>
                <Text weight="medium" style={{ marginBottom: 16 }}>Теперь тебе уже нечего терять. Выбирай сторону: подставляй своих друзей или уведоми их об опасности!</Text>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Button size="l" mode="commerce" style={{ marginRight: 8 }} onClick={shareUrl}>Уведомить</Button>
                    <Button size="l" mode="destructive" onClick={shareUrl}>Подставить</Button>

                </div>
                <Button size='l' stretched onClick={shareStory}>В историю</Button>
                <Caption level="1" weight="regular" style={{ marginTop: 150, color: 'gray', textAlign: 'center' }}>Включи звук, если ты ничего не понял...</Caption>

            </div>
        </div>
        </Panel>
      </View>
    </AppRoot>
  );
};
